<template>
  <div class="missson-adjustment-box" @click="edit = true">
    <headerNav :title="$route.params.marketName + '市场调整'" :leftIcon="true" @leftClick="back" rightNav="调整记录"
      rightIconSvg="a19" rightIconClass="iconClass" @rightClick="toMissionAdjustmentRecord" />
    <div class="content">
      <div class="content-top">
        <van-checkbox v-model="checked" shape="square" icon-size="16px">调整所有关联指标的数值</van-checkbox>
      </div>
      <el-table :data="list" header-cell-class-name="table-head-cell" row-key="code">
        <el-table-column prop="name" label="指标" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.edit" class="required">*</span>
            <span :class="scope.row.type != '0' ? 'text-style change' : 'text-style'">{{ scope.row.name }}{{
              (scope.row.unit && scope.row.name.indexOf('率') == -1 &&
                scope.row.name.indexOf('比例') == -1) ? '(' +
                scope.row.unit + ')' : '' }}</span>
            <van-popover v-model="showHint1" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
              v-if="scope.row.name == '广告费用比例'">
              <div class="hint-text">广告费用占转单产值的比例</div>
              <template #reference>
                <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
              </template>
            </van-popover>
            <van-popover v-model="showHint2" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
              v-if="scope.row.name == '有效量'">
              <div class="hint-text">线索有效量</div>
              <template #reference>
                <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
              </template>
            </van-popover>
          </template>
        </el-table-column>
        <el-table-column prop="beforeAdjustment" label="调整前" align="right" width="95">
          <template slot-scope="scope">
            <span class="number-style" v-if="[0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index)">{{
              scope.row.beforeAdjustment.toFixed(2)
            }}{{
  scope.row.unit == '%' ? '%' : '' }}</span>
            <span class="number-style" v-else>{{
              scope.row.beforeAdjustment
            }}{{
  scope.row.unit == '%' ? '%' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="afterAdjustment" label="调整后" align="right">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit" class="number-style">
              <span v-if="scope.row.code == 2">{{ Number(editInfo.costScale).toFixed(2) }}</span>
              <span v-if="scope.row.code == 4">{{ Number(editInfo.avgValue).toFixed(2) }}</span>
              <span v-if="scope.row.code == 6">{{ Number(editInfo.transferScale).toFixed(2) }}</span>
              <span v-if="scope.row.code == 8">{{ Number(editInfo.signScale).toFixed(2) }}</span>
              <span v-if="scope.row.code == 10">{{ Number(editInfo.shopScale).toFixed(2) }}</span>
              <span v-if="scope.row.code == 12">{{ Number(editInfo.validScale).toFixed(2) }}</span>
              {{ scope.row.unit == '%' ? '%' : '' }}
            </span>
            <div v-else>
              <van-field v-if="scope.row.code == 1" v-model.number="editInfo.transferValue" type="number" name="转单产值"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'transferValue', scope.$index, 99999.99)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 3" v-model.number="editInfo.costFee" type="number" name="广告费用"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'costFee', scope.$index, 99999.99)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 5" v-model.number="editInfo.transferQty" type="digit" name="转单量"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'transferQty', scope.$index, 9999)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 7" v-model.number="editInfo.signQty" type="digit" name="签单量"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'signQty', scope.$index, 9999)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 9" v-model.number="editInfo.shopQty" type="digit" name="到店量"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'shopQty', scope.$index, 9999)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 11" v-model.number="editInfo.validQty" type="digit" name="有效量"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'validQty', scope.$index, 9999)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
              <van-field v-if="scope.row.code == 13" v-model.number="editInfo.clueQty" type="digit" name="线索量"
                class="input-border" @blur="(e) => xiaoshu(e.target._value + '', 'clueQty', scope.$index, 99999)"
                @focus="clickFocus" placeholder="请输入">
              </van-field>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="scale" label="变化" align="right" width="100">
          <template slot-scope="scope">
            <span class="change-text" style="color: #FF6157;" v-if="scope.row.type > 0">+{{ [0, 1, 2, 3, 5, 7, 9,
              11].includes(scope.$index) ? Number(scope.row.scale).toFixed(2) : Number(scope.row.scale).toFixed(0) }}{{
    scope.row.unit == '%' ? '%' : '' }}</span>
            <span class="change-text" style="color: #17CA5E;" v-else-if="scope.row.type < 0">{{ [0, 1, 2, 3, 5, 7, 9,
              11].includes(scope.$index) ? Number(scope.row.scale).toFixed(2) : Number(scope.row.scale).toFixed(0) }}{{
    scope.row.unit == '%' ? '%' : '' }}</span>
            <span class="change-text" v-else>-</span>
          </template>
        </el-table-column>
        <div slot="empty" style="text-align: left;">
          <div class="table-empty">
            <img :src="require('@/assets/a-empty.png')">
            <span>暂无数据</span>
          </div>
        </div>
      </el-table>

    </div>
    <div class="form-btn" v-if="!hideSubmitBtn">
      <van-button block type="info" color="#3975C6" @click="onSubmit" :disabled="getDisabled()">确认</van-button>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api'
import moment from 'moment';
import { isObjectEqual } from '@/utils/index'
import { Toast } from 'vant';

export default {
  data () {
    return {
      checked: true,
      list: [],
      originalInfo: {},
      editInfo: {},
      showHint1: false,
      showHint2: false,
      hideSubmitBtn: false,
      edit: false,
      oldValue: ''
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcost' })

    },
    //获取调整任务详情数据
    async getMissionAdjustment () {
      let obj = {
        taskPeriod: this.$route.params.taskPeriod,
        marketCode: this.$route.params.marketCode
      }
      let res = await api.getMissionAdjustment(obj)
      this.originalInfo = res.data
      this.editInfo = JSON.parse(JSON.stringify(this.originalInfo))
      this.dataTreating()
    },
    dataTreating () {
      this.editInfo.transferValue = Number(this.editInfo.transferValue).toFixed(2)
      this.editInfo.costFee = Number(this.editInfo.costFee).toFixed(2)
      this.list = [
        {
          name: "转单产值",
          code: "1",
          beforeAdjustment: this.originalInfo.transferValue,
          afterAdjustment: this.editInfo.transferValue,
          scale: "-",
          type: 0,
          unit: "万",
          edit: true
        },
        {
          name: "广告费用比例",
          code: "2",
          beforeAdjustment: this.originalInfo.costScale,
          afterAdjustment: this.editInfo.costScale,
          scale: "-",
          type: 0,
          unit: "%"
        },
        {
          name: "广告费用",
          code: "3",
          beforeAdjustment: this.originalInfo.costFee,
          afterAdjustment: this.editInfo.costFee,
          realityNum: 150,
          taskNum: 150,
          scale: "-",
          type: 0,
          unit: "万",
          edit: true

        },
        {
          name: "均单产值",
          code: "4",
          beforeAdjustment: this.originalInfo.avgValue,
          afterAdjustment: this.editInfo.avgValue,
          realityNum: 500,
          taskNum: 1000,
          scale: "-",
          type: 0,
          unit: "元"
        },
        {
          name: "转单量",
          code: "5",
          beforeAdjustment: this.originalInfo.transferQty,
          afterAdjustment: this.editInfo.transferQty,
          realityNum: 500,
          taskNum: 34,
          scale: "-",
          type: 0,
          unit: "单",
          edit: true

        },
        {
          name: "转单率",
          code: "6",
          beforeAdjustment: this.originalInfo.transferScale,
          afterAdjustment: this.editInfo.transferScale,
          realityNum: 70,
          taskNum: 70,
          scale: "-",
          type: 0,
          unit: "%"
        },
        {
          name: "签单量",
          code: "7",
          beforeAdjustment: this.originalInfo.signQty,
          afterAdjustment: this.editInfo.signQty,
          realityNum: 49,
          taskNum: 49,
          scale: "-",
          type: 0,
          unit: "单",
          edit: true

        },
        {
          name: "签单率",
          code: "8",
          beforeAdjustment: this.originalInfo.signScale,
          afterAdjustment: this.editInfo.signScale,
          realityNum: 70,
          taskNum: 70,
          scale: "-",
          type: 0,
          unit: "%"
        },
        {
          name: "到店量",
          code: "9",
          beforeAdjustment: this.originalInfo.shopQty,
          afterAdjustment: this.editInfo.shopQty,
          realityNum: 70,
          taskNum: 70,
          scale: "-",
          type: 0,
          unit: "个",
          edit: true

        },
        {
          name: "到店率",
          code: "10",
          beforeAdjustment: this.originalInfo.shopScale,
          afterAdjustment: this.editInfo.shopScale,
          realityNum: 70,
          taskNum: 70,
          scale: "-",
          type: 0,
          unit: "%"
        },
        {
          name: "有效量",
          code: "11",
          beforeAdjustment: this.originalInfo.validQty,
          afterAdjustment: this.editInfo.validQty,
          realityNum: 100,
          taskNum: 100,
          scale: "-",
          type: 0,
          unit: "条",
          edit: true

        },
        {
          name: "有效率",
          code: "12",
          beforeAdjustment: this.originalInfo.validScale,
          afterAdjustment: this.editInfo.validScale,
          realityNum: 70,
          taskNum: 70,
          scale: "-",
          type: 0,
          unit: "%"
        },
        {
          name: "线索量",
          code: "13",
          beforeAdjustment: this.originalInfo.clueQty,
          afterAdjustment: this.editInfo.clueQty,
          realityNum: 143,
          taskNum: 143,
          scale: "-",
          type: 0,
          unit: "条",
          edit: true
        }
      ]
      this.list.forEach(e => {
        e.scale = e.afterAdjustment - e.beforeAdjustment == 0 ? '-' : (e.afterAdjustment - e.beforeAdjustment).toFixed(2)
        e.type = e.afterAdjustment - e.beforeAdjustment
      })
    },
    //提交
    async onSubmit () {
      let hitMsg = [
        {
          key: 'transferValue',
          msg: '转单产值必须大于0'
        },
        {
          key: 'costFee',
          msg: '广告费用必须大于0'
        },
        {
          key: 'transferQty',
          msg: '转单量必须大于0'
        },
        {
          key: 'signQty',
          msg: '签单量必须大于0'
        },
        {
          key: 'shopQty',
          msg: '到店量必须大于0'
        },
        {
          key: 'validQty',
          msg: '有效量必须大于0'
        },
        {
          key: 'clueQty',
          msg: '线索量必须大于0'
        }
      ]
      let msg = ''
      hitMsg.forEach(e => {
        if (!this.editInfo[e.key] && !msg) {
          msg = e.msg
        }
      })
      if (msg) {
        Toast(msg);
        return
      }
      let res = await api.missionAdjustment(this.editInfo)
      if (res.code == 200) {
        Toast('调整成功!');
        this.$router.push({
          name: 'TaskAdvcost'
        })
      }
    },
    //前往调整记录
    toMissionAdjustmentRecord () {
      this.$router.push({ name: 'TaskAdvcostMissionAdjustmentRecord' })
    },
    clickFocus (e) {
      this.oldValue = JSON.parse(JSON.stringify(e.target._value))
    },
    xiaoshu (value, key, index, max) {
      value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
      value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
      if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      if (value > max) value = max
      this.editInfo[key] = index < 4 ? Number(value).toFixed(2) : value
      if (this.edit) {
        if (value != this.oldValue) this.calculateCost(index)
        this.oldValue = JSON.parse(JSON.stringify(value))
      }
    },
    //判断数据是否改变 获取禁用状态
    getDisabled () {
      return isObjectEqual(this.editInfo, this.originalInfo)
    },
    //计算相关费用
    calculateCost (index) {
      if (this.checked) {
        if (index == 2) {
          this.editInfo.costScale = (this.editInfo.costFee * 100 / this.editInfo.transferValue).toFixed(2)//广告费用比例
        } else if (index < 4 && index != 2) {
          this.editInfo.costFee = (this.editInfo.transferValue * this.editInfo.costScale / 100).toFixed(2)//广告费用
        }
        if (index == 4) {
          this.editInfo.avgValue = (this.editInfo.transferValue * 10000 / this.editInfo.transferQty).toFixed(2)//均单产值

        } else if (index < 6 && index != 4) {
          this.editInfo.transferQty = Math.ceil(this.editInfo.transferValue * 10000 / this.editInfo.avgValue)//转单量
        }
        if (index == 6) {
          this.editInfo.transferScale = (this.editInfo.transferQty * 100 / this.editInfo.signQty).toFixed(2) //转单率

        } else if (index < 8 && index != 6) {
          this.editInfo.signQty = Math.ceil(this.editInfo.transferQty / (this.editInfo.transferScale / 100))//签单量
        }
        if (index == 8) {
          this.editInfo.signScale = (this.editInfo.signQty * 100 / this.editInfo.shopQty).toFixed(2) //签单率

        } else if (index < 10 && index != 8) {
          this.editInfo.shopQty = Math.ceil(this.editInfo.signQty / (this.editInfo.signScale / 100))//到店量
        }
        if (index == 10) {
          this.editInfo.shopScale = (this.editInfo.shopQty * 100 / this.editInfo.validQty).toFixed(2) //到店率

        } else if (index < 12 && index != 10) {
          this.editInfo.validQty = Math.ceil(this.editInfo.shopQty / (this.editInfo.shopScale / 100))//有效量
        }
        if (index == 12) {
          this.editInfo.validScale = (this.editInfo.validQty * 100 / this.editInfo.clueQty).toFixed(2) //有效率

        } else if (index < 14 && index != 12) {
          this.editInfo.clueQty = Math.ceil(this.editInfo.validQty / (this.editInfo.validScale / 100))//线索量
        }
      } else {
        if (index == 0) {
          this.editInfo.avgValue = (this.editInfo.transferValue * 10000 / this.editInfo.transferQty).toFixed(2)//均单产值
        }
        if (index == 0 || index == 2) {
          this.editInfo.costScale = (this.editInfo.costFee * 100 / this.editInfo.transferValue).toFixed(2)//广告费用比例
        }
        if (index == 4) {
          this.editInfo.avgValue = (this.editInfo.transferValue * 10000 / this.editInfo.transferQty).toFixed(2)//均单产值
          this.editInfo.transferScale = (this.editInfo.transferQty * 100 / this.editInfo.signQty).toFixed(2) //转单率
        }
        if (index == 6) {
          this.editInfo.transferScale = (this.editInfo.transferQty * 100 / this.editInfo.signQty).toFixed(2) //转单率
          this.editInfo.signScale = (this.editInfo.signQty * 100 / this.editInfo.shopQty).toFixed(2) //签单率
        }
        if (index == 8) {
          this.editInfo.signScale = (this.editInfo.signQty * 100 / this.editInfo.shopQty).toFixed(2) //签单率
          this.editInfo.shopScale = (this.editInfo.shopQty * 100 / this.editInfo.validQty).toFixed(2) //到店率
        }
        if (index == 10) {
          this.editInfo.shopScale = (this.editInfo.shopQty * 100 / this.editInfo.validQty).toFixed(2) //到店率
          this.editInfo.validScale = (this.editInfo.validQty * 100 / this.editInfo.clueQty).toFixed(2) //有效率
        }
        if (index == 12) {
          this.editInfo.validScale = (this.editInfo.validQty * 100 / this.editInfo.clueQty).toFixed(2) //有效率
        }
      }
      for (let key in this.editInfo) {
        if (this.editInfo[key] == 0 || this.editInfo[key] == NaN || this.editInfo[key] == Infinity) {
          this.editInfo[key] = 0
        }
      }
      this.dataTreating()
    }
  },
  mounted () { },
  created () {
    this.getMissionAdjustment()
  }
}
</script>

<style scoped lang="scss">
.missson-adjustment-box {
  .content {
    height: calc(100vh - 140px);
    overflow: auto;
    background: #F5F5F5;
    // padding-bottom: 80px;

    box-sizing: border-box;
  }

  .table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 100px;
    }

    span {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #5E5E5E;
    }
  }

  .content-top {
    height: 52px;
    background: #FFFFFF;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #545454;
    display: flex;
    align-items: center;
    padding: 0 12px;
    justify-content: end;

    ::v-deep {
      .van-checkbox__icon--checked .van-icon {
        color: #3DA2FF;
        background: #FFFFFF;
        border: 1px solid #3DA2FF;
      }
    }
  }
}

::v-deep {
  .input-border {
    padding: 0;

    &>div {
      display: flex;
      align-items: center;
      text-align: left;
      flex-wrap: wrap;
    }

    input {
      height: 35px;
      border: 1px solid #EEEEEE;
      border-radius: 2px;
      padding-left: 5px;
      width: 100%;
      font-size: 15px;
      font-family: Bahnschrift, Bahnschrift;
      font-weight: normal;
    }

    .van-field__body {
      margin-left: auto;
    }

    .van-field__error-message {
      width: 175px;
      text-align: left;
      margin-left: auto;
    }
  }

  .cell {
    padding: 0 8px;
    &>span{
      text-wrap: wrap;
    }
    &:has(.number-style) {
      padding-left: 0;
    }
    &:has(.change-text){
      padding-left: 0;
    }
  }
}

.hint-icon {
  width: 13px;
  margin-left: 2px;
  margin-bottom: -2px;
}

.hint-popover {
  height: 16px;
  display: flex;
}

.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

.text-style {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
}

.number-style {
  font-size: 15px;
  font-family: Bahnschrift, Bahnschrift;
  font-weight: normal;
  color: #8C8C8C;
}

.change {
  color: #1D2129;
}

.change-text {
  font-size: 15px;
  font-family: Bahnschrift, Bahnschrift;
  font-weight: normal;
}

.form-btn {
  background: #FFFFFF;
  display: flex;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 12px;
  align-items: center;
  padding-bottom: 20px;
  height: 90px;
  button {
    border-radius: 4px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}

.required {
  color: #FF6157;
}

::v-deep {
  .cell {
    line-height: normal;

    &:has(.text-style) {
      padding-right: 0;
    }
  }

  .iconClass {
    margin-bottom: -2px;
    color: red;
    margin-right: 2px;
  }
}
</style>
<style lang="scss">
.table-head-cell {
  background: #F5F5F5 !important;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  color: #1D2129;

  .cell {
    box-sizing: border-box;
  }
}
</style>